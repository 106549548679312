import * as z from 'zod'

export const UserSchema = z.object({
	_id: z.any().optional(),
	name: z.string().nonempty().min(3),
	description: z.string().optional(),
	username: z.string().nonempty(),
	email: z.string().email().optional(),
	pictureURL: z.string().optional(),
	password: z.string().optional(),
	active: z.boolean().optional()
})

export type UserType = z.TypeOf<typeof UserSchema>
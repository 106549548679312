import * as z from 'zod'

import { api } from '../../utils/api'
import { UserModuleSchema } from '../../models/modules/userModule'
import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty()
})])

const OutputSchema = z.promise(z.array(UserModuleSchema))

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const GetModules = FunctionSchema.implement(async ({ username }) => await api.get(isDev ? `/user/${username}/modules/GET` : `/user/${username}/modules`))
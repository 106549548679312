import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'
import { UserSchema } from '../../models/user/user'

const InputSchema = z.tuple([])

const OutputSchema = z.promise(z.union([UserSchema, z.boolean()]))

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const GetLogged = FunctionSchema.implement(async () => await api.get(isDev ? `/user/logged/GET` : `/user/logged`))
import React from 'react'
import { observer } from 'mobx-react-lite'
import * as z from 'zod'
import { Modal, Card } from 'antd'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import { Whatsapp } from '@styled-icons/remix-fill/Whatsapp'
import { Telegram } from '@styled-icons/bootstrap/Telegram'
import { Linkedin } from 'styled-icons/bootstrap'
import { Instagram } from '@styled-icons/bootstrap/Instagram'
import { TelephoneFill } from '@styled-icons/bootstrap/TelephoneFill'
import { Link } from '@styled-icons/entypo/Link'
import { Email } from '@styled-icons/material-twotone/Email'

import { useContext } from '../../../context'
import { UserModuleSchema } from '../../../../../models/modules/userModule'

interface AddDialogProps {
	show: boolean
	close: () => void
}

export const AddDialog: React.FC<AddDialogProps> = observer(({ show, close }) => {

	const context = useContext()

	type ModuleTypes = z.TypeOf<typeof UserModuleSchema.shape.module>

	const doAdd = React.useCallback((kind: ModuleTypes) => () => {
		let position = 1
		context.modules = [
			{
				_id: `${Date.now()}`,
				isNew: true,
				module: kind,
				data: {} as any,
				position: 1
			},
			...context.modules.map(module => {
				position++
				return {
					...module,
					position
				}
			}),
		]
		close()
	}, [context, close])

	return (
		<Modal
			centered
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`Adicionar Módulo`}</p>
					<p className={`text-sm font-normal opacity-50`}>{`Selecione um módulo para configurar...`}</p>
				</div>
			}
			visible={show}
			closable={false}
			footer={
				<div className={`p-5`}>
					<button onClick={close} className={`w-full focus:outline-none rounded-lg bg-gray-500 px-5 py-2 text-white`}>{`Cancelar`}</button>
				</div>
			}
		>
			<div className={`flex p-5 flex-col space-y-5`}>
				<Card onClick={doAdd('WHATSAPP')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<Whatsapp className={`w-8 text-green-600`} />
							<p>{`Whatsapp`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
				<Card onClick={doAdd('TELEGRAM')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<Telegram className={`w-8 text-blue-400`} />
							<p>{`Telegram`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
				<Card onClick={doAdd('EXTERNAL_URL')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<Link className={`text-gray-500 w-8`} />
							<p>{`Link`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
				<Card onClick={doAdd('LINKEDIN')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<Linkedin className={`text-blue-700 w-8`} />
							<p>{`LinkedIn`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
				<Card onClick={doAdd('INSTAGRAM')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<Instagram className={`text-purple-500 w-8`} />
							<p>{`Instagram`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
				<Card onClick={doAdd('PHONE')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<TelephoneFill className={`w-8`} />
							<p>{`Telefone`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
				<Card onClick={doAdd('EMAIL')} className={`cursor-pointer border-gray-100 shadow-lg border rounded-lg p-5`}>
					<div className={`flex flex-row justify-between`}>
						<div className={`flex flex-row space-x-5 items-center`}>
							<Email className={`text-yellow-500 w-8`} />
							<p>{`E-mail`}</p>
						</div>
						<ChevronRight className={`w-8 text-gray-500`} />
					</div>
				</Card>
			</div>
		</Modal>
	)
})
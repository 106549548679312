import * as z from 'zod'
import axios from 'axios'

import { GetPictureUploadURL } from './getPictureUploadURL'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty(),
	pictureURL: z.string()
})])

const OutputSchema = z.promise(z.boolean())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const UploadNewProfilePicture = FunctionSchema.implement(async ({ username, pictureURL }) => {
	try {
		let blob = await fetch(pictureURL).then(r => r.blob())
		const uploadURL = await GetPictureUploadURL({ username })
		await axios.put(uploadURL, blob)
		return true
	} catch (e) {
		return false
	}
})
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { useStateful, useBoolean } from 'react-hanger'
import { Pencil } from '@styled-icons/boxicons-solid/Pencil'
import { observer } from 'mobx-react-lite'
import { Card, message } from 'antd'

import { ProfileEditor } from './components/profileEditor'
import { PictureEditor } from './components/pictureEditor'
import { useContext } from '../../context'
import { GetPublicProfile } from '../../../../controllers/user/getPublicProfile'
import { GetPictureDownloadURL } from '../../../../controllers/user/getPictureDownloadURL'
import { GetLinkTarget } from '../../../../controllers/link/getTarget'

export const ProfileHeader = observer(() => {

	const history = useHistory()
	const urlParams = useParams<any>()
	const context = useContext()
	const imageURL = useStateful<string | undefined>(undefined)
	const fileField = React.useRef<any>()
	const showPictureEditDialog = useBoolean(false)
	const showProfileEditDialog = useBoolean(false)

	const onCrop = React.useCallback(async (url) => {
		context.profile = {
			...context.profile,
			pictureURL: url
		}
		showPictureEditDialog.setFalse()
	}, [context, showPictureEditDialog])

	const onEnterEditMode = React.useCallback(async () => {
		context.saveStore()
		context.editingScreen = true
	}, [context])

	const openFileField = React.useCallback(() => {
		if (!fileField.current) return
		fileField.current.click()
	}, [fileField])

	const onImageChange = React.useCallback(event => {
		if (event.target.files && event.target.files[0]) {
			imageURL.setValue(URL.createObjectURL(event.target.files[0]))
			showPictureEditDialog.setTrue()
			fileField.current.value = ``
		}
	}, [imageURL, showPictureEditDialog])

	const loadLink = React.useCallback(async () => {
		try {
			const link = await GetLinkTarget({ link: urlParams.link })
			if (link !== null) history.replace(link)

			const profile = await GetPublicProfile({ username: link || urlParams.link })
			context.profile = profile
			const pictureURL = await GetPictureDownloadURL({ username: profile.username })
			context.profile = { ...context.profile, pictureURL }

		} catch (e) {
			switch (e) {
				case `USER_NOT_FOUND`:
					history.replace(`/`)
					break
			}
			message.error(`Erro ao carregar perfil...`)
		}
	}, [context, urlParams, history])

	React.useEffect(() => {
		loadLink()
	}, [])  // eslint-disable-line

	return (
		<Card className={classnames({
			'border-0': (context.profile?.pictureURL ?? ``).length > 0
		})}>
			<div className={`overflow-hidden flex flex-row`}>
				{context.profile && (
					<>
						<div
							className={`w-32 bg-cover bg-center`}
							style={{ backgroundImage: `url(${context.profile?.pictureURL ?? ``})` }}
						>
							{context.editingScreen && (
								<div onClick={openFileField} className={`cursor-pointer text-white bg-black h-full bg-opacity-50 flex items-center justify-center`} >
									<Pencil className={`w-10 opacity-60`} />
								</div>
							)}
						</div>
						<div className={`flex-1 m-5 flex flex-col space-y-5`}>
							<div className={`h-10 space-x-5 flex flex-row justify-between`}>
								<div>
									<div className={`font-bold`}>{context.profile?.name ?? ``}</div>
									<div className={`text-xs opacity-50`}>{`@${context.profile?.username ?? ``}`}</div>
								</div>
								{context.editingScreen && (<Pencil className={`cursor-pointer w-4 opacity-50`} onClick={showProfileEditDialog.setTrue} />)}
								{
									(
										!context.editingScreen
										&& `${context.loggedUser?._id || `1`}` === `${context.profile?._id || `2`}`
									)
									&& (
										<Pencil onClick={onEnterEditMode} className={`cursor-pointer w-4 opacity-50`} />
									)
								}
							</div>
							<div className={`text-xs opacity-50 italic`}>{context.profile?.description ?? ``}</div>
						</div>
					</>
				)}
			</div>
			<PictureEditor onCrop={onCrop} setImageURL={imageURL.setValue} imageURL={imageURL.value} close={showPictureEditDialog.setFalse} show={showPictureEditDialog.value} />
			<ProfileEditor close={showProfileEditDialog.setFalse} show={showProfileEditDialog.value} />
			<input accept={`image/*`} className={`hidden`} ref={fileField} type="file" onChange={onImageChange} />
		</Card>
	)
})
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useBoolean } from 'react-hanger'
import { UserCircle } from '@styled-icons/heroicons-outline/UserCircle'
import { message, Modal, Tooltip } from 'antd'
import { Logout } from '@styled-icons/heroicons-outline/Logout'
import { Password } from '@styled-icons/fluentui-system-filled/Password'
import { ShareAlt } from '@styled-icons/boxicons-solid/ShareAlt'

import { Login } from './components/login'
import logoEscrito from '../../../../assets/logoEscrito.png'
import { useContext } from '../../context'
import { GetLogged } from '../../../../controllers/user/getLogged'
import { UserType } from '../../../../models/user/user'
import { DoLogout } from '../../../../controllers/user/doLogout'
import { PasswordChange } from './components/passwordChange'
import { ShareModal } from './components/shareModal'

export const Topbar = observer(() => {

	const loadingLoggedUser = useBoolean(false)
	const context = useContext()
	const showLoginDialog = useBoolean(false)
	const showPasswordChangeDialog = useBoolean(false)
	const showShareModal = useBoolean(false)

	const doLogout = React.useCallback((confirmed?: boolean) => async () => {
		if (confirmed) {
			try {
				await DoLogout()
				context.loggedUser = undefined as any
			} catch (e) {
				message.error(`Erro ao fazer logout! Tente novamente...`)
			}
		} else {
			Modal.confirm({
				title: false,
				icon: false,
				content: `Deseja realmente sair ?`,
				okText: `Sim`,
				cancelText: `Não`,
				onOk: doLogout(true)
			})
		}
	}, [context])

	const getLoggedUser = React.useCallback(async () => {
		loadingLoggedUser.setTrue()
		try {
			const loggedUser = await GetLogged()
			if (!!loggedUser) context.loggedUser = loggedUser as UserType
		} catch (e) {
			message.error(`Erro ao carregar usuário logado...`)
		}
		loadingLoggedUser.setFalse()
	}, [context, loadingLoggedUser])

	React.useEffect(() => {
		getLoggedUser()
	}, [])  // eslint-disable-line

	return (
		<div className={`text-white backdrop-filter backdrop-blur bg-black bg-opacity-20 py-2 px-5 flex flex-row items-center justify-between`}>
			{
				!loadingLoggedUser.value
					? (
						<>
							{
								context.loggedUser
									? <div>
										{
											(
												context.profile
												&& context.loggedUser
												&& context.loggedUser.username === context.profile.username
											)
											&& <Tooltip title={`Alterar senha`}>
												<Password onClick={showPasswordChangeDialog.setTrue} className={`text-white w-8 cursor-pointer mr-2`} />
											</Tooltip>
										}
										<Tooltip title={`Sair da conta`}>
											<Logout onClick={doLogout()} className={`text-white w-8 cursor-pointer`} />
										</Tooltip>
									</div>
									:
									<Tooltip title={`Entrar`}>
										<UserCircle onClick={showLoginDialog.setTrue} className={`text-white w-8 cursor-pointer`} />
									</Tooltip>
							}
						</>
					)
					: (
						<div className={`w-8`} />
					)
			}
			<img alt={``} src={logoEscrito} className={`h-6`} />
			<Tooltip title={`Compartilhar`}>
				<ShareAlt onClick={showShareModal.setTrue} className={`text-white w-8 cursor-pointer`} />
			</Tooltip>
			<Login close={showLoginDialog.setFalse} show={showLoginDialog.value} />
			<PasswordChange close={showPasswordChangeDialog.setFalse} show={showPasswordChangeDialog.value} />
			<ShareModal close={showShareModal.setFalse} show={showShareModal.value} />
		</div>
	)
})
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([z.object({
	link: z.string().nonempty()
})])

const OutputSchema = z.promise(z.string().nullable())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const GetLinkTarget = FunctionSchema.implement(async ({ link }) => await api.get(isDev ? `/link/${link}/getTarget/GET` : `/link/${link}/getTarget`))
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty()
})])

const OutputSchema = z.promise(z.string().url())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const GetPictureUploadURL = FunctionSchema.implement(async ({ username }) => await api.get(isDev ? `/user/${username}/picture/uploadURL/GET` : `/user/${username}/picture/uploadURL`))
import { useBoolean } from 'react-hanger'
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle'
import { observer } from 'mobx-react-lite'
import { Card } from 'antd'

import { AddDialog } from './components/addDialog'
import { useContext } from '../../context'

export const AddModule = observer(() => {

	const context = useContext()
	const showDialog = useBoolean(false)

	return (
		<>
			{
				context.editingScreen
				&& (
					<Card className={`cursor-pointer`} onClick={showDialog.setTrue}>
						<div className={`bg-opacity-80 flex flex-col space-y-2 items-center m-2`}>
							<PlusCircle className={`w-8 text-gray-500`} />
							<div className={`font-bold`}>{`Adicionar Módulo`}</div>
						</div>
					</Card>
				)
			}
			<AddDialog close={showDialog.setFalse} show={showDialog.value} />
		</>
	)
})
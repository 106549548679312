import * as z from 'zod'

export const UserModuleSchema = z.object({
	_id: z.string().nonempty(),
	isNew: z.boolean().default(false).optional(),
	position: z.number().min(1),
	user: z.string().optional(),
	module: z.enum([`EXTERNAL_URL`, `LINKEDIN`, `EMAIL`, `PHONE`, `TELEGRAM`, `WHATSAPP`, `INSTAGRAM`]),
	data: z.any()
})

export type UserModuleType = z.TypeOf<typeof UserModuleSchema>
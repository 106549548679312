import React from 'react'
import { observer } from 'mobx-react-lite'
import { LoaderAlt } from '@styled-icons/boxicons-regular/LoaderAlt'
import { Modal, message } from 'antd';
import { useInput, useBoolean } from 'react-hanger'

import { ChangeLoggedUserPassword } from '../../../../../controllers/user/changeLoggedUserPassword'

interface ProfileEditorProps {
	show: boolean
	close: () => void
}

export const PasswordChange: React.FC<ProfileEditorProps> = observer(({ show, close }) => {

	const currentPassword = useInput()
	const newPassword = useInput()
	const newPasswordConfirmation = useInput()
	const loading = useBoolean(false)

	const doChange = React.useCallback(async (confirmed?: boolean) => {
		if (confirmed) {
			try {
				loading.setTrue()
				await ChangeLoggedUserPassword({
					currentPassword: currentPassword.value,
					newPassword: newPassword.value
				})
				loading.setFalse()
				message.success(`Senha alterada com sucesso!`)
				close()
			} catch (e) {
				switch (e) {
					case `WRONG_CURRENT_PASSWORD`:
						message.error(`Senha atual incorreta! Tente novamente...`)
						break
					default:
						message.error(`Erro ao trocar senha! Tente novamente...`)
						break
				}
				loading.setFalse()
			}
		} else {
			Modal.confirm({
				content: `Confirmar a troca da senha?`,
				okText: `Sim`,
				cancelText: `Não`,
				onOk: () => doChange(true)
			})
		}
	}, [close, currentPassword.value, loading, newPassword.value])

	const canChange = React.useMemo(() => (
		currentPassword.value.length >= 4
		&& newPassword.value.length >= 4
		&& newPassword.value === newPasswordConfirmation.value
	), [currentPassword.value, newPasswordConfirmation.value, newPassword.value])

	React.useEffect(() => {
		if (show) {
			currentPassword.setValue(``)
			newPassword.setValue(``)
			newPasswordConfirmation.setValue(``)
		}
	}, [show]) // eslint-disable-line

	return (
		<Modal
			centered
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`Trocar senha`}</p>
				</div>
			}
			visible={show}
			closable={false}
			footer={
				<div className={`p-5 flex flew-col space-x-5`}>
					<button onClick={close} className={`focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white`}>{`Cancelar`}</button>
					<button disabled={!canChange} onClick={() => doChange()} className={`disabled:opacity-50 focus:outline-none rounded-lg w-full bg-blue-400 px-5 py-2 text-white`}>{`Alterar`}</button>
				</div>
			}
		>
			<div className={`flex p-5 flex-col space-y-5`}>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Senha atual:`}</p>
					<input onChange={currentPassword.onChange} value={currentPassword.value} type={`password`} className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Nova senha:`}</p>
					<input onChange={newPassword.onChange} value={newPassword.value} type={`password`} className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Nova senha (confirmação):`}</p>
					<input onChange={newPasswordConfirmation.onChange} value={newPasswordConfirmation.value} type={`password`} className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
			</div>
			<Modal className={`w-auto`} centered title={false} footer={false} closable={false} visible={loading.value}>
				<div className={`p-5 flex flex-col items-center justify-center`}>
					<LoaderAlt className={`animate-spin	opacity-50 w-8`} />
					<div>{`Alterando senha...`}</div>
				</div>
			</Modal>
		</Modal>
	)
})
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from 'antd'
import InputMask from 'inputmask'

import { PhoneSchema, UserModuleType, PhoneType } from '../../../../../../models/'
import { useContext } from '../../../../context'

const phoneMask = new InputMask({ mask: `+99 (99) 99999999[9]` })

interface Props {
	close: () => void
	show: boolean
	module: UserModuleType
}

export const EditModal: React.FC<Props> = observer(({ close, module, show }) => {

	const context = useContext()

	const phoneInput = React.useRef<any>()
	const phoneMaskInstance = React.useRef<InputMask.Instance>()

	const data = React.useMemo(() => module.data as PhoneType, [module.data])

	const canClose = React.useMemo(() => PhoneSchema.shape.phone.safeParse(data.phone).success, [data.phone])

	const onPhoneInput = React.useCallback(() => {
		context.modules = context.modules.map(m => (
			m._id === module._id
				? {
					...module,
					data: {
						...module.data,
						phone: phoneMaskInstance.current?.unmaskedvalue() ?? ``
					}
				}
				: m
		))
	}, [context, module])

	const onAliasInput = React.useCallback(({ target: { value } }) => {
		context.modules = context.modules.map(m => (
			m._id === module._id
				? {
					...module,
					data: {
						...module.data,
						alias: value
					}
				}
				: m
		))
	}, [context, module])

	const onCancelNew = React.useCallback(() => {
		context.modules = context.modules.filter(m => m._id !== module._id)
		close()
	}, [context, close, module])

	const onClose = React.useCallback(() => {
		context.modules = context.modules.map(m => m._id === module._id ? { ...module, isNew: false } : m)
		close()
	}, [context, close, module])

	React.useEffect(() => {
		if (phoneInput.current) phoneMaskInstance.current = phoneMask.mask(phoneInput.current)
	}, [show, phoneInput])

	return (
		<Modal
			centered
			visible={show}
			closable={false}
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`${module.isNew ? `Configurar` : `Editar`} Telefone`}</p>
				</div>
			}
			footer={
				<div className={`p-5 flex flex-row space-x-5`}>
					{
						module.isNew
							? (
								<>
									<button onClick={onCancelNew} className={`flex-1 focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white`}>{`Cancelar`}</button>
									<button disabled={!canClose} onClick={onClose} className={`flex-1 focus:outline-none rounded-lg w-full bg-green-500 px-5 py-2 text-white disabled:opacity-50`}>{`Salvar`}</button>
								</>
							)
							: (
								<button disabled={!canClose} onClick={onClose} className={`flex-1 focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white disabled:opacity-50`}>{`Fechar`}</button>
							)
					}
				</div>
			}
		>
			<div className={`flex flex-col space-y-5 p-5`}>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Legenda:`}</p>
					<input onInput={onAliasInput} value={data.alias ?? ``} type="text" className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
				<div>
					<p className={`text-black text-opacity-50 mb-2 flex flex-row space-x-1`}>
						<div>{`Número:`}</div>
						<div className={`text-red-500`}>{`*`}</div>
					</p>
					<input ref={phoneInput} onChange={onPhoneInput} value={data.phone ?? `55`} type={`text`} inputMode={`tel`} className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
			</div>
		</Modal>
	)
})
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'
import { UserSchema } from '../../models'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty()
})])

const OutputSchema = z.promise(UserSchema)

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const GetPublicProfile = FunctionSchema.implement(async ({ username }) => await api.get(isDev ? `/user/${username}/publicProfile/GET` : `/user/${username}/publicProfile`))
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'
import { UserModuleSchema } from '../../models/modules/userModule'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty(),
	modules: z.array(UserModuleSchema)
})])

const OutputSchema = z.promise(z.boolean())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const UpdateModules = FunctionSchema.implement(async ({ username, modules }) => await api.post(isDev ? `/user/${username}/modules/POST` : `/user/${username}/modules`, { modules }))
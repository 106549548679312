import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { observer } from 'mobx-react-lite'
import { message } from 'antd'
import { useBoolean } from 'react-hanger'

import { AddModule } from './addModule'
import { WhatsappModule } from './modules/whatsapp'
import { TelegramModule } from './modules/telegram'
import { PhoneModule } from './modules/phone'
import { EmailModule } from './modules/email'
import { LinkedInModule } from './modules/linkedin'
import { InstagramModule } from './modules/instagram'
import { ExternalUrlModule } from './modules/externalUrl'
import { useContext } from '../context'
import { GetModules } from '../../../controllers/user/getModules'

export const ModulesList = SortableContainer(observer(() => {

	const context = useContext()
	const loading = useBoolean(false)

	const modules = React.useMemo(() => context.modules.slice().sort((a, b) => a.position < b.position ? -1 : 1), [context.modules])

	const loadModules = async () => {
		if (!context.profile) return
		loading.setTrue()
		try {
			const modules = await GetModules({ username: context.profile.username }) as any
			context.modules = modules
		} catch (e) {
			message.error(`Erro ao carregar dados...`)
		}
		loading.setFalse()
	}

	React.useEffect(() => {
		loadModules()
	}, [context.profile])  // eslint-disable-line

	return (
		<div className={`space-y-5 overflow-auto flex-1 flex flex-col`}>
			<AddModule />
			{!loading.value && (
				<>
					{modules.map(m => {
						// @ts-expect-error
						if (m.module === `WHATSAPP`) return <WhatsappModule key={`item-${m.position}`} index={m.position} module={m} />
						// @ts-expect-error
						if (m.module === `TELEGRAM`) return <TelegramModule key={`item-${m.position}`} index={m.position} module={m} />
						// @ts-expect-error
						if (m.module === `PHONE`) return <PhoneModule key={`item-${m.position}`} index={m.position} module={m} />
						// @ts-expect-error
						if (m.module === `EMAIL`) return <EmailModule key={`item-${m.position}`} index={m.position} module={m} />
						// @ts-expect-error
						if (m.module === `INSTAGRAM`) return <InstagramModule key={`item-${m.position}`} index={m.position} module={m} />
						// @ts-expect-error
						if (m.module === `LINKEDIN`) return <LinkedInModule key={`item-${m.position}`} index={m.position} module={m} />
						// @ts-expect-error
						if (m.module === `EXTERNAL_URL`) return <ExternalUrlModule key={`item-${m.position}`} index={m.position} module={m} />
						return null
					})}
				</>
			)}
		</div>
	)
}))
import * as z from 'zod'
import URL from 'url'
import path from 'path'

import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty()
})])

const OutputSchema = z.string()

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const GetUserVCard = FunctionSchema.implement(({ username }) => {
	const APIURL = URL.parse(process.env.REACT_APP_API_URL)
	APIURL.pathname = isDev ? path.join(APIURL.pathname!, `/user/${username}/vcard/GET`) : path.join(APIURL.pathname!, `/user/${username}/vcard`)
	return URL.format(APIURL)
})
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from 'antd'
import { useInput, useBoolean } from 'react-hanger'
import { QrCode } from '@styled-icons/fluentui-system-regular'
import { SaveAlt } from '@styled-icons/material-rounded/SaveAlt'
import { MoreHorizontalOutline } from '@styled-icons/evaicons-outline/MoreHorizontalOutline'
import QRCode from "react-qr-code"

import { GetUserVCard } from '../../../../../controllers/user/getUserVcard'
import { useContext } from '../../../context'

interface ProfileEditorProps {
	show: boolean
	close: () => void
}

export const ShareModal: React.FC<ProfileEditorProps> = observer(({ show, close }) => {

	const context = useContext()
	const currentPassword = useInput()
	const newPassword = useInput()
	const newPasswordConfirmation = useInput()
	const showQrCodeModal = useBoolean(false)

	React.useEffect(() => {
		if (show) {
			currentPassword.setValue(``)
			newPassword.setValue(``)
			newPasswordConfirmation.setValue(``)
		}
	}, [show]) // eslint-disable-line

	const showQrCode = () => {
		showQrCodeModal.setTrue()
		close()
	}

	const downloadVcard = () => {
		window.location.href = GetUserVCard({ username: context.profile.username })
		close()
	}
	const moreShareOptions = () => navigator.share({ url: window.location.href }).then(() => close())

	return (
		<>
			<Modal
				centered
				title={
					<div className={`p-5`}>
						<p className={`text-lg font-bold`}>{`Compartilhar`}</p>
					</div>
				}
				visible={show}
				closable={false}
				footer={
					<div className={`p-5 flex flew-col space-x-5`}>
						<button onClick={close} className={`focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white`}>{`Cancelar`}</button>
					</div>
				}
			>
				<div className={`flex p-5 flex-col space-y-5`}>
					<button onClick={showQrCode} className={`border-gray-300 border focus:outline-none rounded-lg w-full px-5 py-2 text-gray-500`}>
						<QrCode className={`w-20`} />
						<br />
						{`Gerar QR Code`}
					</button>
					<button onClick={downloadVcard} className={`border-gray-300 border focus:outline-none rounded-lg w-full px-5 py-2 text-gray-500`}>
						<SaveAlt className={`w-20 mb-2`} />
						<br />
						{`Salvar contato`}
					</button>
					<button onClick={moreShareOptions} className={`border-gray-300 border focus:outline-none rounded-lg w-full px-5 py-2 text-gray-500`}>
						<MoreHorizontalOutline className={`w-20`} />
						<br />
						{`Mais opções`}
					</button>
				</div>
			</Modal >
			<Modal
				visible={showQrCodeModal.value}
				closable={false}
				width={`fit-content`}
				footer={
					<div className={`p-5 flex flew-col space-x-5`}>
						<button onClick={showQrCodeModal.setFalse} className={`focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white`}>{`Fechar`}</button>
					</div>
				}
			>
				<div className={`flex p-5 flex-col space-y-5`}>
					<QRCode value={window.location.href} />
				</div>
			</Modal>
		</>
	)
})
import React, { useCallback } from 'react'
import { Modal, Card } from 'antd'
import { LoaderAlt } from '@styled-icons/boxicons-regular/LoaderAlt'
import { observer } from 'mobx-react-lite'
import { diff } from 'deep-diff'
import { toJS } from 'mobx'
import { useBoolean } from 'react-hanger'

import { useContext } from '../context'
import { UpdateModules } from '../../../controllers/user/updateModules'
import { UpdateProfile } from '../../../controllers/user/updateProfile'
import { UploadNewProfilePicture } from '../../../controllers/user/uploadNewProfilePicture'

export const EditingBar = observer(() => {

	const context = useContext()
	const saving = useBoolean(false)
	const confirmModal = React.useRef<any>()

	const onSave = useCallback(async () => {
		if (confirmModal.current) confirmModal.current.destroy()
		saving.setTrue()
		context.editingScreen = false
		const previousContext = JSON.parse(context.savedStore!)
		const pictureDiffs = diff(toJS(context.profile.pictureURL), previousContext._profile.pictureURL) || []
		if (pictureDiffs.length > 0) await UploadNewProfilePicture({ username: context.profile.username, pictureURL: context.profile.pictureURL! })
		const profileDiffs = diff(toJS(context.profile), previousContext._profile) || []
		if (profileDiffs.length > 0) await UpdateProfile({ username: context.profile.username, profile: context.profile })
		const modulesDiffs = diff(toJS(context.modules), previousContext._modules) || []
		if (modulesDiffs.length > 0) await UpdateModules({ username: context.profile.username, modules: context.modules })
		saving.setFalse()
	}, [context, saving])

	const onCancel = React.useCallback(() => context.restoreStore(), [context])

	const confirmSave = React.useCallback(() => {
		confirmModal.current = Modal.confirm({
			title: false,
			icon: false,
			content: `Deseja realmente salvar ?`,
			okText: `Salvar e sair`,
			onOk: onSave
		})
	}, [onSave])

	const confirmCancel = React.useCallback(() => {
		Modal.confirm({
			title: false,
			icon: false,
			content: `Deseja realmente cancelar ?\nTodas as alterações serão periddas!`,
			okText: `Sair sem salvar`,
			onOk: onCancel
		})
	}, [onCancel])

	return (
		<>
			{
				context.editingScreen
				&& (
					<Card>
						<div className={`bg-opacity-80 flex flex-row space-x-2 items-center m-2`}>
							<button onClick={confirmCancel} className={`rounded-lg flex-1 bg-red-500 p-2 text-white`}>{`Cancelar`}</button>
							<button onClick={confirmSave} className={`rounded-lg flex-1 bg-green-500 p-2 text-white`}>{`Salvar`}</button>
						</div>
					</Card>
				)
			}
			<Modal visible={saving.value} closable={false} footer={false} title={false}>
				<div className={`p-5 flex flex-col space-y-5 items-center justify-center`}>
					<LoaderAlt className={`animate-spin	opacity-50 w-8`} />
					<p className={`opacity-50`}>{`Salvando dados...`}</p>
				</div>
			</Modal>
		</>
	)
})
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([z.object({
	currentPassword: z.string().nonempty().min(4),
	newPassword: z.string().nonempty().min(4),
})])

const OutputSchema = z.promise(z.union([z.string(), z.boolean()]))

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const ChangeLoggedUserPassword = FunctionSchema.implement(async ({ currentPassword, newPassword }) => await api.put(isDev ? `/user/logged/password/PUT` : `/user/logged/password`, { currentPassword, newPassword }))
import React from 'react'
import { Modal } from 'antd'
import { useStateful } from 'react-hanger'
import { observer } from 'mobx-react-lite'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

interface PictureEditorProps {
	show: boolean
	close: () => void
	imageURL?: string
	setImageURL: (url: string) => void
	onCrop: (url: string) => void
}

export const PictureEditor: React.FC<PictureEditorProps> = observer(({ onCrop, show, close, imageURL, setImageURL }) => {

	const cropObj = useStateful<any>({})

	const imageRef = React.useRef<any>()

	const canCrop = React.useMemo(() => (cropObj.value.width > 0 && cropObj.value.height > 0), [cropObj])

	const doCrop = React.useCallback(() => {
		const canvas = document.createElement('canvas')
		const scaleX = imageRef.current.naturalWidth / imageRef.current.width
		const scaleY = imageRef.current.naturalHeight / imageRef.current.height
		canvas.width = cropObj.value.width
		canvas.height = cropObj.value.height
		const ctx = canvas.getContext('2d')!

		ctx.drawImage(
			imageRef.current,
			cropObj.value.x * scaleX,
			cropObj.value.y * scaleY,
			cropObj.value.width * scaleX,
			cropObj.value.height * scaleY,
			0,
			0,
			cropObj.value.width,
			cropObj.value.height
		)

		canvas.toBlob(blob => {
			// @ts-expect-error
			onCrop(URL.createObjectURL(blob))
		}, 'image/jpeg')
	}, [imageRef, cropObj, onCrop])

	React.useEffect(() => {
		if (show) cropObj.setValue({
			aspect: 1 / 1,
			unit: `%`,
			x: 10,
			y: 10,
			width: 80,
			height: 80,
		})
	}, [show])  // eslint-disable-line

	return (
		<Modal
			centered
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`Editar Foto`}</p>
				</div>
			}
			visible={show}
			closable={false}
			footer={
				<div className={`p-5 flex flex-row space-x-5`}>
					<button onClick={close} className={`flex-1 focus:outline-none rounded-lg bg-gray-500 px-5 py-2 text-white`}>{`Fechar`}</button>
					<button disabled={!canCrop} onClick={doCrop} className={`flex-1 focus:outline-none rounded-lg bg-green-500 px-5 py-2 text-white disabled:opacity-50`}>{`Salvar`}</button>
				</div>
			}
		>
			<div className={`flex p-5 flex-col space-y-5`}>
				{imageURL && (
					<ReactCrop
						imageStyle={{ width: `100%` }}
						src={imageURL}
						crop={cropObj.value}
						onImageLoaded={ref => ref ? imageRef.current = ref : null}
						onChange={cropObj.setValue}
					/>
				)}
			</div>
		</Modal>
	)
})
import * as z from 'zod'

export enum LinkTypes {
	user = `user`
}

export const LinkSchema = z.object({
	_id: z.any().optional(),
	link: z.string().nonempty(),
	targetType: z.nativeEnum(LinkTypes),
	targetId: z.string().nonempty(),
	active: z.boolean(),
})

export type LinkType = z.TypeOf<typeof LinkSchema>
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import "antd/dist/antd.css"

import './index.css'
import { App } from './App'

ReactDOM.render(
  <React.StrictMode>
    {/* @ts-expect-error */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById(`root`)
)
import React from 'react'
import { useBoolean } from 'react-hanger'
import { Whatsapp } from '@styled-icons/remix-fill/Whatsapp'
import { Pencil } from '@styled-icons/boxicons-solid/Pencil'
import { Trash2 } from '@styled-icons/bootstrap/Trash2'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import { SortableElement } from 'react-sortable-hoc'
import { observer } from 'mobx-react-lite'
import { Modal, Card } from 'antd'
import classnames from 'classnames'

import { DragHandle } from '../../dragHandle'
import { UserModuleType, WhatsappType } from '../../../../../models'
import { EditModal } from './components/edit'
import { useContext } from '../../../context'

interface Props {
	module: UserModuleType
}

export const WhatsappModule = SortableElement(observer(({ module }: Props) => {

	const context = useContext()

	const data = React.useMemo(() => module.data as WhatsappType, [module.data])

	const showConfig = useBoolean(false)

	const openWhatsapp = React.useCallback(() => {
		if (context.editingScreen) return
		window.open(`https://wa.me/${data.phone}?text=${data.message ?? ``}`)
	}, [context.editingScreen, data])

	const removeModule = React.useCallback((confirmed?: boolean) => () => {
		if (confirmed) {
			context.modules = context.modules.filter(m => m._id !== module._id)
		} else {
			Modal.confirm({
				title: false,
				icon: false,
				content: `Deseja remover o módulo "Whatsapp" ?`,
				onOk: removeModule(true)
			})
		}
	}, [context, module])

	React.useEffect(() => {
		if (module.isNew) showConfig.setTrue()
	}, [])  // eslint-disable-line

	return (
		<Card className={classnames({ 'cursor-pointer': !context.editingScreen })} onClick={openWhatsapp}>
			<div className={`h-10 flex flex-row space-x-4 items-center m-4`}>
				{/* @ts-expect-error */}
				{context.editingScreen && <DragHandle />}
				<Whatsapp className={`w-8 text-green-600`} />
				<div className={`flex-1`}>
					<div className={`font-bold`}>{`Whatsapp`}</div>
					<div className={`text-xs opacity-50`}>{data.alias}</div>
				</div>
				<div className={`flex flex-row space-x-5`}>
					{
						context.editingScreen
							? (
								<>
									<Pencil onClick={showConfig.setTrue} className={`w-5 mr-1 text-gray-500`} />
									<Trash2 onClick={removeModule()} className={`w-5 text-red-500`} />
								</>
							)
							: (
								<ChevronRight className={`w-8 text-gray-500`} />
							)
					}
				</div>
			</div>
			<EditModal show={showConfig.value} module={module} close={showConfig.setFalse} />
		</Card>
	)
}))
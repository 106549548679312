import React from 'react'
import { observer } from 'mobx-react-lite'
import { LoaderAlt } from '@styled-icons/boxicons-regular/LoaderAlt'
import { Modal, message } from 'antd';
import { useInput, useBoolean } from 'react-hanger'
import { useParams } from 'react-router-dom'

import { useContext } from '../../../context'
import { DoLogin } from '../../../../../controllers/user/doLogin'
import { GetLogged } from '../../../../../controllers/user/getLogged'
import { UserType } from '../../../../../models/user/user'

interface ProfileEditorProps {
	show: boolean
	close: () => void
}

export const Login: React.FC<ProfileEditorProps> = observer(({ show, close }) => {

	const context = useContext()
	const pathParams = useParams<any>()
	const password = useInput()
	const loading = useBoolean(false)

	const doLogin = React.useCallback(async () => {
		loading.setTrue()
		try {
			await DoLogin({ username: pathParams.link, password: password.value })
			const loggedUser = await GetLogged()
			if (!!loggedUser) context.loggedUser = loggedUser as UserType
			password.setValue(``)
			loading.setFalse()
			close()
		} catch (e) {
			loading.setFalse()
			message.error(`Erro ao fazer login! Tente novamente...`)
		}
	}, [close, context, pathParams, password, loading])

	const canLogin = React.useMemo(() => password.value.length >= 4, [password.value])

	const inputOnPressEnter = (field: string) => ({ key }: any) => {
		if (key !== `Enter`) return
		switch (field) {
			case `password`: {
				doLogin()
			}
		}
	}

	return (
		<Modal
			centered
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`Identificação`}</p>
				</div>
			}
			visible={show}
			closable={false}
			footer={
				<div className={`p-5 flex flew-col space-x-5`}>
					<button tabIndex={3} onClick={close} className={`focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white`}>{`Cancelar`}</button>
					<button tabIndex={2} disabled={!canLogin} onClick={doLogin} className={`disabled:opacity-50 focus:outline-none rounded-lg w-full bg-blue-400 px-5 py-2 text-white`}>{`Entrar`}</button>
				</div>
			}
		>
			<div className={`flex p-5 flex-col space-y-5`}>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Usuário:`}</p>
					<input readOnly value={context.profile?.username ?? ``} type="text" className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Senha:`}</p>
					<input autoFocus onKeyPress={inputOnPressEnter(`password`)} tabIndex={1} onChange={password.onChange} value={password.value} type={`password`} className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
			</div>
			<Modal className={`w-auto`} centered title={false} footer={false} closable={false} visible={loading.value}>
				<div className={`p-5 flex flex-col items-center justify-center`}>
					<LoaderAlt className={`animate-spin	opacity-50 w-8`} />
					<div>{`Fazendo login...`}</div>
				</div>
			</Modal>
		</Modal>
	)
})
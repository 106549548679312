import React from 'react'
import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { useContext } from '../../../context'
import { UserSchema } from '../../../../../models/user/user';

interface ProfileEditorProps {
	show: boolean
	close: () => void
}

export const ProfileEditor: React.FC<ProfileEditorProps> = observer(({ show, close }) => {

	const context = useContext()

	const onNameInput = React.useCallback(({ target: { value } }) => {
		context.profile = { ...context.profile, name: value }
	}, [context])

	const onDescriptionInput = React.useCallback(({ target: { value } }) => {
		context.profile = { ...context.profile, description: value }
	}, [context])

	const canClose = React.useMemo(() => UserSchema.shape.name.safeParse(context.profile?.name ?? ``).success, [context.profile])

	return (
		<Modal
			centered
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`Editar Perfil`}</p>
				</div>
			}
			visible={show}
			closable={false}
			footer={
				<div className={`p-5`}>
					<button disabled={!canClose} onClick={close} className={`focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white disabled:opacity-50`}>{`Fechar`}</button>
				</div>
			}
		>
			<div className={`flex p-5 flex-col space-y-5`}>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Nome completo:`}</p>
					<input onInput={onNameInput} value={context.profile?.name ?? ``} type="text" className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Descrição:`}</p>
					<textarea onInput={onDescriptionInput} value={context.profile?.description ?? ``} className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`}></textarea>
				</div>
			</div>
		</Modal>
	)
})
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'
import { UserSchema } from '../../models'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty(),
	profile: UserSchema
})])

const OutputSchema = z.promise(z.boolean())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const UpdateProfile = FunctionSchema.implement(async ({ username, profile }) => await api.post(isDev ? `/user/${username}/publicProfile/POST` : `/user/${username}/publicProfile`, { profile }))
import React from 'react'
import arrayMove from 'array-move'
import { observer } from 'mobx-react-lite'
import { LoaderAlt } from '@styled-icons/boxicons-regular/LoaderAlt'
import { Modal } from 'antd'

import { ProfileHeader } from './components/profileHeader'
import { ModulesList } from './components/modulesList'
import { EditingBar } from './components/editingBar'
import { useContext } from './context'
import { Topbar } from './components/topbar'

export const UserProfile = observer(() => {

	const context = useContext()

	const onSortEnd = React.useCallback(({ oldIndex, newIndex }) => {
		context.modules = arrayMove(context.modules, oldIndex - 1, newIndex - 1).map((m, i) => ({ ...m, position: i + 1 }))
	}, [context])

	return (
		<div
			className={`bg-cover bg-center bg-no-repeat w-full h-full flex flex-col overflow-hidden`}
			style={{ backgroundImage: `url(${context.profile?.pictureURL ?? ``})` }}
		>
			<title>{context.profile?.name ? `${context.profile.name} - minhatag.digital` : `minhatag.digital`}</title>
			<Topbar />
			<div className={`backdrop-filter backdrop-blur p-5 flex-1 flex flex-col space-y-5  overflow-hidden`}>
				<Modal mask={false} className={`w-auto`} centered title={false} footer={false} closable={false} visible={!context.profile}>
					<div className={`p-5 flex flex-col items-center justify-center`}>
						<LoaderAlt className={`animate-spin	opacity-50 w-8`} />
					</div>
				</Modal>
				<EditingBar />
				<ProfileHeader />
				{/* @ts-expect-error */}
				<ModulesList onSortEnd={onSortEnd} useDragHandle />
			</div>
		</div>
	)
})
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([z.object({
	username: z.string().nonempty(),
	password: z.string().nonempty()
})])

const OutputSchema = z.promise(z.boolean())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const DoLogin = FunctionSchema.implement(async ({ username, password }) => await api.post(isDev ? `/user/login/POST` : `/user/login`, { username, password }))
import * as z from 'zod'

import { api } from '../../utils/api'
import { isDev } from '../../utils/isDev'

const InputSchema = z.tuple([])

const OutputSchema = z.promise(z.boolean())

const FunctionSchema = z.function(InputSchema, OutputSchema)

export const DoLogout = FunctionSchema.implement(async () => await api.post(isDev ? `/user/logout/POST` : `/user/logout`))
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from 'antd'

import { TelegramSchema, UserModuleType, TelegramType } from '../../../../../../models/'
import { useContext } from '../../../../context'

interface Props {
	close: () => void
	show: boolean
	module: UserModuleType
}

export const EditModal: React.FC<Props> = observer(({ close, module, show }) => {

	const context = useContext()

	const data = React.useMemo(() => module.data as TelegramType, [module.data])

	const canClose = React.useMemo(() => TelegramSchema.shape.username.safeParse(data.username).success, [data.username])

	const onUsernameInput = React.useCallback(({ target: { value } }) => {
		context.modules = context.modules.map(m => (
			m._id === module._id
				? {
					...module,
					data: {
						...module.data,
						username: value
					}
				}
				: m
		))
	}, [context, module])

	const onAliasInput = React.useCallback(({ target: { value } }) => {
		context.modules = context.modules.map(m => (
			m._id === module._id
				? {
					...module,
					data: {
						...module.data,
						alias: value
					}
				}
				: m
		))
	}, [context, module])

	const onCancelNew = React.useCallback(() => {
		context.modules = context.modules.filter(m => m._id !== module._id)
		close()
	}, [context, close, module])

	const onClose = React.useCallback(() => {
		context.modules = context.modules.map(m => m._id === module._id ? { ...module, isNew: false } : m)
		close()
	}, [context, close, module])

	return (
		<Modal
			centered
			visible={show}
			closable={false}
			title={
				<div className={`p-5`}>
					<p className={`text-lg font-bold`}>{`${module.isNew ? `Configurar` : `Editar`} Telegram`}</p>
				</div>
			}
			footer={
				<div className={`p-5 flex flex-row space-x-5`}>
					{
						module.isNew
							? (
								<>
									<button onClick={onCancelNew} className={`flex-1 focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white`}>{`Cancelar`}</button>
									<button disabled={!canClose} onClick={onClose} className={`flex-1 focus:outline-none rounded-lg w-full bg-green-500 px-5 py-2 text-white disabled:opacity-50`}>{`Salvar`}</button>
								</>
							)
							: (
								<button disabled={!canClose} onClick={onClose} className={`flex-1 focus:outline-none rounded-lg w-full bg-gray-500 px-5 py-2 text-white disabled:opacity-50`}>{`Fechar`}</button>
							)
					}
				</div>
			}
		>
			<div className={`flex flex-col space-y-5 p-5`}>
				<div>
					<p className={`text-black text-opacity-50 mb-2`}>{`Legenda:`}</p>
					<input onInput={onAliasInput} value={data.alias ?? ``} type="text" className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
				<div>
					<p className={`text-black text-opacity-50 mb-2 flex flex-row space-x-1`}>
						<div>{`Usuário:`}</div>
						<div className={`text-red-500`}>{`*`}</div>
					</p>
					<input onChange={onUsernameInput} value={data.username ?? ``} type="text" className={`bg-gray-100 w-full outline-none border-gray-200 border p-2 rounded-lg`} />
				</div>
			</div>
		</Modal>
	)
})
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Switch, Route } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import classnames from 'classnames'

import { UserProfile } from './pages/userProfile'
import { GlobalStyles } from './components/globalStyles'

import { registerServiceWorker } from './registreSW'

const MobileSimulator: React.FC = observer(({ children }) => {
  return (
    <div className={classnames({
      'w-full h-full': true,
      'flex items-center justify-center bg-gray-300': !isMobile
    })}>
      <div className={isMobile ? `w-full h-full` : `bg-white w-10/12 sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 h-5/6 rounded-lg overflow-hidden shadow-lg`}>
        {children}
      </div>
    </div>
  )
})

export const App = observer(() => {
  useEffect(() => {
    registerServiceWorker()
  }, [])  // eslint-disable-line

  return (
    <MobileSimulator>
      <Switch>
        <Route path={`/:link`} component={UserProfile} />
      </Switch>
      <GlobalStyles />
    </MobileSimulator>
  )
})
import React from 'react'
import { toJS, makeAutoObservable } from 'mobx'

import { UserModuleType } from '../../models/modules/userModule'
import { UserType } from '../../models/user/user'

class Context {
	constructor() {
		makeAutoObservable(this)
	}

	private _savedStore?: string = undefined
	get savedStore() {
		return this._savedStore
	}
	restoreStore() {
		if (!this._savedStore) return
		const data = JSON.parse(this._savedStore)
		for (const key in data) {
			if (!data.hasOwnProperty(key)) continue
			if (key === `_savedStore`) continue
			(this as any)[key] = data[key]
		}
		this._savedStore = undefined
	}
	saveStore() {
		this._savedStore = JSON.stringify(toJS(this))
	}

	private _editingScreen: boolean = false
	get editingScreen() {
		return this._editingScreen
	}
	set editingScreen(editingScreen: boolean) {
		this._editingScreen = editingScreen
	}

	private _modules: UserModuleType[] = []
	get modules() {
		return this._modules
	}
	set modules(modules: UserModuleType[]) {
		this._modules = modules
	}

	private _loggedUser?: UserType = undefined
	get loggedUser() {
		return this._loggedUser as UserType
	}
	set loggedUser(loggedUser: UserType) {
		this._loggedUser = loggedUser
	}

	private _profile?: UserType = undefined
	get profile() {
		return this._profile as UserType
	}
	set profile(profile: UserType) {
		this._profile = profile
	}
}

export const context = new Context()

export const useContext = () => React.useContext(React.createContext(context))
import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

export const GlobalStyles = createGlobalStyle`
	.ant-message-notice-content{
		${tw`rounded-lg shadow-lg`}
	}

	.ant-card{
		${tw`shadow-lg border border-gray-100 flex-shrink-0 rounded-lg overflow-hidden`}

		& .ant-card-body{
			${tw`w-full p-0`}
		}
	}

	.ant-btn{
		${tw`rounded-lg border-0 bg-gray-500 text-white`}

		&:hover{
			${tw`bg-gray-500 text-white`}
		}
		&.ant-btn-primary{
			${tw`bg-green-500`}

			&:hover{
				${tw`bg-green-500 text-white`}
			}
		}
	}

	.ant-modal{

		&.ant-modal-confirm{
			.ant-modal-confirm-body-wrapper{
				${tw`p-5 whitespace-pre`}
			}

			.ant-modal-confirm-btns{
				${tw`w-full flex space-x-5`}

				.ant-btn{
					${tw`flex-1`}
				}
			}
			
			.ant-modal-confirm-content{
				${tw`text-center`}
			}
		}

		.ant-modal-content{
			${tw`rounded-lg overflow-hidden`}
			
			.ant-modal-body{
				${tw`p-0`}
			}
			.ant-modal-header{
				${tw`p-0`}
			}
			.ant-modal-footer{
				${tw`p-0`}
			}
		}
	}
`